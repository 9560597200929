import { FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { ReactNode } from 'react';
import type { CheckboxProps } from '@mui/material';

import BaseCheckbox from 'components/Forms/Inputs/BaseCheckbox';
import Typography from 'components/ui/Atoms/Typography';
import styles from './CheckboxStyles';

const useStyles = makeStyles({ name: 'Checkbox' })(styles);

type Props = CheckboxProps & { className?: string, description?: ReactNode, label: string };

const Checkbox = (props: Props) => {
  // Props
  const { className = '', label, description = null, ...checkboxProps } = props;

  // Hooks
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <FormControlLabel
        classes={{
          root: classes.label,
          label: classes.labelValue,
        }}
        control={(<BaseCheckbox {...checkboxProps} />)}
        label={label}
      />
      {description && (
        <Typography
          className={classes.description}
          variant="caption2"
          component="span"
        >
          {description}
        </Typography>
      )}
    </div>
  );
};

export default Checkbox;
