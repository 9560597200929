import type { BatonTheme } from 'theme';

const styles = (theme: BatonTheme) => {
  const { baseUnit, typography, palette } = theme;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: baseUnit * 2,
    },
    label: {
      gap: baseUnit,
      margin: 0,
    },
    labelValue: {
      ...typography.body1,
      alignItems: 'center',
      display: 'flex',
      lineHeight: 1,
    },
    description: {
      color: palette.text.secondary,
      margin: `${baseUnit / 2}px 0 0 ${baseUnit * 7}px`, // 24px + 4px padding
    },
  };
};

export default styles;
