import { Checkbox } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { CheckboxProps } from '@mui/material';

import CheckboxIcon from 'components/ui/Atoms/Icons/Checkbox';
import styles from './BaseCheckboxStyles';

const useStyles = makeStyles({ name: 'BaseCheckbox' })(styles);

const BaseCheckbox = (props: CheckboxProps) => {
  // Props
  const { className, disabled, ...otherProps } = props;

  // Hooks
  const { classes, cx } = useStyles();

  return (
    <Checkbox
      className={cx(classes.root, className)}
      icon={<CheckboxIcon disabled={disabled} />}
      checkedIcon={<CheckboxIcon disabled={disabled} active />}
      disabled={disabled}
      {...otherProps}
    />
  );
};

export default BaseCheckbox;
