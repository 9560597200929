import type { BatonTheme } from 'theme';

const styles = (theme: BatonTheme) => {
  const { palette } = theme;

  return {
    root: {
      backgroundColor: 'transparent',
      color: palette.icons.gray,
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
};

export default styles;
